import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";
// import { FiArrowRightCircle } from "react-icons/fi";
// import { Link } from "gatsby";
// import Image from "@components/common/CloudinaryImage";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
// import { InlineWidget } from "react-calendly";
// import { FaParking } from "react-icons/fa";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>Et presentem una NOVETAT enguany.</p>

                <p>
                    El curs d'introducció a l'OAC per als alumnes que han finalitzat la 37a promoció
                    i que ben aviat s'incorporaran al seu destí.
                </p>

                <p>
                    Es tracta d'un curs molt complet per aprendre i interioritzar tots els conceptes
                    com:
                </p>
                <ul className="space-y-6 list">
                    <li>- L'atestat i la seva estructura</li>
                    <li>- Diligencies</li>
                    <li>- Procediment d'habeas corpus</li>
                    <li>- Diligencies amb menors</li>
                    <li>- Tramitació de tríptics i actes per infracció</li>
                    <li>- Criteris per judici ràpid/procediment ordinari</li>
                    <li>- Diligencies per delictes de violència en l'àmbit de la llar.</li>
                    <li>- La minuta policial</li>
                    <li>- I molt més.</li>
                </ul>
                <p>Impartit per un dels millors docents.</p>
                <p>El pròxim dissabte 31 d'agost de 9.00 a 14.00 h a través de ZOOM</p>
                <p>
                    La gravació quedarà penjada durant <strong>seixanta dies</strong>
                </p>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Aprèn les bases de l'OAC en aquest curs online interactiu, impartit en directe per Zoom. Apunta't i domina els conceptes clau en només un matí!"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};
export default Product;
